@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

@layer base {
  body {
    @apply font-[Montserrat];
  }
  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}

.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
}

.content-div:hover {
  background-image: linear-gradient(
    to bottom right,
    #FD00E3,#0C7DCB,#03F8FF
  )!important;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #FD00E3;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media only screen and (max-width: 768px) and (orientation: landscape) {
  .navbar {
    display: none;
  }
  .hamburger-menu {
    display: block;
  }
}